<template>
  <header class="pg-header bg-dark sticky-top py-3">
    <div class="container d-flex flex-wrap justify-content-center">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <KnkLogo color="#fff"/>
        <span class="fs-4 color-white">KinaKong</span>
      </a>

      <button data-mdb-toggle="sidenav" data-mdb-target="#sidenav-1" class="btn" aria-controls="#sidenav-1" aria-haspopup="true">
        <i class="fas fa-bars"></i>
      </button>

      <ul class="nav">
        <li class="nav-item"><a href="/" class="nav-link active" aria-current="page">Галоўная</a></li>
        <li class="nav-item"><a href="/video" class="nav-link">Відэа</a></li>
        <li class="nav-item"><a href="/about" class="nav-link">Пра сэрвіс</a></li>
        <li class="nav-item"><a href="/contact" class="nav-link">Кантакты</a></li>
      </ul>
    </div>
  </header>
</template>

<script>
import KnkLogo from "@/components/Parts/KnkLogo.vue";
export default {
  name: 'PageHeader',
  components: {KnkLogo},
  methods: {
  }
}
</script>

<style scoped>
.nav-item a {
  padding-left: 10px;
  padding-right: 10px;
}
.nav-item:first-child a {
  padding-left: 0
}
.nav-item:last-child a {
  padding-right: 0
}

.pg-header {
  background-color: rgba(0, 0, 0, .7);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0 1px 0 #ababab;
}

.pg-header a {
  color: #dedede;
  transition: color .2s ease-in-out;
}

.pg-header a:hover {
  color: #fff;
}
</style>
