<template>
  <PageVideoBase db-url="/db/movie/PPR.json"/>
</template>

<script>
import PageVideoBase from "@/components/Pages/Video/PageVideoBase.vue";

export default {
  components: {
    PageVideoBase
  },
}
</script>
