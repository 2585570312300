<template>
  <section class="text-bg-dark">
    <div class="container py-4">
      <h2 class="display-5 text-center">
        {{ title }}
      </h2>

      <div class="row">
        <div class="col col-md-8 offset-md-2 text-center">
          <p class="p-desc">
            {{ description }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-9">
          <div class="d-md-flex flex-md-equal w-100">
            <div class="w-100 text-center overflow-hidden">
              <h3>{{ current.title }}</h3>
              <div class="player-wrapper w-100 pb-5">
                <video id="kVideo" v-if="current" autobuffer preload="auto" playsinline controls>
                  <source id="kSource" :src="current.path" type="video/mp4">
                  <p>
                    Ваш браўзер не падтрымлівае праграванне відэа.
                  </p>
                </video>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 pb-3 mb-3" v-if="this.episodes.length > 0">
          <h3>Усе серыі</h3>
          <div class="list-episodes list-group bg-dark">
            <div v-for="(item,index) in this.episodes" v-bind:key="index">
              <div :data-href="item.path"
                 @click="setEpisode(item)"
                 class="list-group-item list-group-item-action bg-dark text-light">
                <div class="d-flex align-items-center">
                  <div class="pic me-3">
                    <span class="number">{{ item.number }}</span>
                    <img src="../../../assets/img/pic_courage.jpg" alt="{{ item.title }}">
                  </div>
                  <h5 class="mb-1">{{ item.title }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-9 pb-3">
          <h4>Інфармацыя пра твор</h4>
          <div v-html="information"></div>
        </div>

        <div class="col-sm-12 col-md-3 pb-3">
          <h4>Тэхнічная інфармацыя</h4>
          <p class="technical">
            Сайт выкарыстоўвае JavaScript, не захоўвае Cookie.
            Відэапрайграванне падтрымліваецца сучаснымі браўзерамі: Firefox, Chrome, Edge, Safari.
            Магчымы невялікія праблемы пры выкарыстанні Opera.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PageVideoCourage',
  data() {
    return {
      title: '',
      description: '',
      information: '',
      kVideo: null,
      kSource: null,
      current: {},
      episodes: [],
    }
  },
  mounted() {
    this.getInfo().then((videoInfo) => {
      this.title = videoInfo.title;
      this.description = videoInfo.description;
      this.information = videoInfo.information;
      this.episodes = videoInfo.list || [];

      this.kVideo = document.getElementById('kVideo');
      this.kSource = document.getElementById('kSource');
      this.kVideo.load();

      if (this.episodes.length > 0) {
        setTimeout(() => {
          this.setEpisode(this.episodes ? this.episodes.slice(0, 1)[0] : null);
        }, 175);
      }
    });
  },
  methods: {
    setMediaSession(title, artist) {
      if (!('mediaSession' in navigator)) {
        return;
      }
      navigator.mediaSession.metadata = new MediaMetadata({
        title: title,
        artist: artist,
        artwork: [
          {
            src: '/assets/img/pic_ronia.jpg',
            type: 'image/jpg'
          }
        ]
      });
    },
    setEpisode(item) {
      if (item) {
        this.current = item;
        this.kSource = item.path;
        this.kVideo.load();
        document.title = item.title + ' | Кураж | KinaKong';
        this.setMediaSession(item.title, 'Кураж — палахлівы сабака')
      } else {
        this.current = null;
        this.kSource = null;
      }
    },

    async getInfo() {
      try {
        const response = await axios.get('/db/movie/kurazh.json');
        return response.data;
      } catch (error) {
        return null;
      }
    }
  }
}

document.title = "Кураж | KinaKong";
</script>

<style scoped>
video {
  max-width: 100%;
}

.pic {
  position: relative;
}

.pic .number {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 32px;
  z-index: 8;
  color: #000;
  font-weight: bold;
  line-height: 1.75;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, .8), 2px 2px 0 rgba(255, 255, 255, .8);
}

.pic img {
  max-width: 112px;
}

.list-episodes {
  max-height: 540px;
  overflow-y: scroll
}

.list-group-item-action {
  cursor: pointer;
}

.technical {
  color: #dedede;
  font-size: 80%;
}
</style>
