<template>
  <div>
    <BlockHero/>
    <BlockPlay/>
  </div>
</template>

<script setup>
import BlockPlay from "@/components/Parts/BlockPlay.vue";
import BlockHero from "@/components/Parts/BlockHero.vue";

document.title = "Галоўная | KinaKong";
</script>
