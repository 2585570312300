<template>
  <section>
    <div class="container py-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 offset-md-3">
          <h1>Пра нас</h1>
          <p>
            <strong>Кінаконг</strong> — праект, які займаецца прафесійнай агучкай кіно на беларускую мову і яго дыстрыб’юцыяй.
            З 2016 года напрацягу некалькіх гадоў мы ладзілі рэгулярныя паказы фільмаў па-беларуску ў кінатэатрах.
            Так нарадзіўся сумесны з кампаніяй velcom (цяпер А1) праект <strong>«Беларускія ўікэнды»</strong>.
            За некалькі гадоў «Беларускія ўікэнды» наведала 50 тысяч гледачоў. Паказы фільмаў адбыліся ў 35 гарадах Беларусі.
          </p>

          <p>
            На сённяшні дзень фільмы і серыялы па-беларуску, у тым ліку перакладзеныя намі, можна глядзець у раздзеле
            CineVoka <a href="https://voka.tv/">Voka.Tv</a>.
          </p>

          <p>
            На гэтым сайце мы выкладаем некаторыя выбраныя пераклады для бясплатнага прагляду.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageAbout',
  mounted() {
    document.title = "Пра нас | KinaKong";
  }
};
</script>
