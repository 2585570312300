import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(
    VueGtag,
    {
        appName: 'MovieKinaKong',
        pageTrackerScreenviewEnabled: false,
        config: { id: 'G-BD1C2EVXPJ' }
    }
)
app.mount('#app')
