<template>
  <div class="hero-wr position-relative overflow-hidden p-4 p-md-5">
    <div class="decor decor-left"></div>
    <div class="decor decor-right"></div>
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 fw-normal">
        Анлайн-паказы з Кінаконгам!
      </h1>
      <p class="lead fw-normal">
        У гэтым раздзеле вы можаце глядзець некаторыя відэа з нашым агучваннем на беларускую мову!
      </p>
    </div>
  </div>
</template>

<style scoped>
.hero-wr {
  background: url('../../assets/img/bg_nature.jpg') 50% 50%;
  position: relative;
}
.lead {
  text-shadow: 1px 1px 1px rgba(255, 255, 255, .7);
}

@media screen and (min-width: 1024px ) {
  @keyframes moveLeftRight {
    0% {
      margin-left: 0;
    }
    50% {
      margin-left: 42px;
    }
    100% {
      margin-left: 0;
    }
  }

  @keyframes moveUpDown {
    0% {
      margin-bottom: 0;
    }
    50% {
      margin-bottom: 40px;
    }
    100% {
      margin-bottom: 0;
    }
  }

  .decor {
    position: absolute;
  }
  .decor-left {
    background: url('../../assets/img/ronja/Ronja.png') 50% 50%;
    width: 280px;
    height: 390px;
    left: 5vw;
    top: 12%;
    animation: moveLeftRight 5s infinite;
  }
  .decor-right {
    background: url('../../assets/img/ronja/Birk.png') 50% 50%;
    width: 152px;
    height: 280px;
    right: 5vw;
    bottom: 12%;
    animation: moveUpDown 5s infinite;
  }
}
</style>

<script>
export default {
  name: 'BlockHero',
  props: {
    msg: String
  }
}
</script>
