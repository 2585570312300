<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 1200 1200" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
      <path d="M1202 10980 c-61 -38 -72 -69 -72 -215 0 -112 3 -134 20 -163 37 -60
71 -72 200 -72 145 -1 191 -17 220 -82 18 -40 20 -68 20 -396 0 -223 -4 -369
-11 -396 -7 -27 -24 -57 -45 -77 -34 -33 -35 -34 -157 -39 -134 -7 -167 -18
-210 -70 -15 -17 -29 -45 -32 -63 -3 -18 -4 -1569 -3 -3447 3 -3012 5 -3419
18 -3445 24 -49 72 -65 217 -73 110 -7 134 -11 160 -30 61 -44 64 -66 61 -477
-3 -338 -5 -372 -21 -397 -37 -54 -62 -63 -202 -68 -210 -8 -235 -34 -235
-240 0 -144 12 -181 70 -215 l35 -20 4772 0 4772 0 27 21 c64 47 69 63 69 214
0 124 -2 141 -21 166 -45 61 -64 68 -207 74 -150 6 -184 18 -214 80 -16 31
-18 74 -21 375 -4 390 0 427 54 474 41 37 48 38 192 45 131 5 166 20 197 81
20 40 20 63 23 3395 2 2253 -1 3381 -8 3433 -12 95 -33 143 -74 168 -26 16
-51 19 -152 19 -109 0 -124 2 -155 23 -19 12 -43 40 -54 62 -19 37 -20 64 -23
385 -4 380 1 430 46 473 37 35 90 47 207 47 123 0 159 15 193 80 19 38 22 60
22 155 0 132 -21 188 -80 217 -33 17 -284 18 -4800 18 -4737 0 -4765 0 -4798
-20z m2258 -485 c16 -8 39 -29 52 -47 l23 -33 0 -390 0 -390 -23 -33 c-45 -62
-44 -62 -444 -62 -346 0 -365 1 -396 20 -19 12 -41 38 -52 63 -18 39 -20 68
-20 402 0 334 2 363 20 402 11 25 33 51 52 63 31 19 50 20 396 20 302 0 368
-3 392 -15z m1983 -24 l42 -40 3 -387 c2 -267 -1 -397 -8 -415 -6 -15 -25 -40
-41 -55 l-30 -29 -384 0 -384 0 -30 29 c-16 15 -35 40 -41 55 -7 18 -10 147
-8 411 3 367 4 386 23 412 44 58 37 57 443 58 l373 0 42 -39z m1934 19 c68
-41 68 -42 68 -465 0 -423 0 -422 -68 -464 -33 -20 -46 -21 -396 -21 -399 0
-398 0 -443 62 -23 33 -23 33 -26 413 -3 425 -3 426 67 472 34 23 36 23 400
23 345 0 368 -1 398 -20z m1968 -10 c55 -52 55 -49 55 -457 0 -360 -1 -380
-20 -411 -11 -18 -34 -41 -52 -52 -31 -19 -51 -20 -391 -20 -398 0 -408 1
-447 65 -19 31 -20 49 -20 421 0 364 1 391 19 418 10 15 32 37 47 47 27 18 50
19 406 17 l376 -3 27 -25z m881 -1476 c58 -39 54 186 54 -3006 0 -2924 0
-2933 -20 -2966 -11 -18 -36 -40 -55 -48 -30 -12 -598 -14 -4206 -14 -3787 0
-4174 1 -4204 16 -19 9 -42 28 -52 42 -17 26 -18 149 -21 2947 -2 2038 0 2931
8 2957 6 20 24 48 41 62 l31 26 4199 0 c3884 0 4202 -1 4225 -16z m-6766
-6579 c16 -8 39 -29 52 -47 l23 -33 0 -390 c0 -434 2 -423 -68 -465 -30 -19
-53 -20 -395 -20 -400 0 -399 0 -444 62 l-23 33 0 380 c0 422 1 428 64 471
l36 24 363 0 c301 0 368 -3 392 -15z m1958 -5 c18 -11 41 -34 52 -52 19 -31
20 -51 20 -411 0 -420 -1 -427 -65 -467 -31 -19 -49 -20 -400 -20 -351 0 -369
1 -400 20 -64 40 -65 47 -65 467 0 360 1 380 20 411 11 18 34 41 52 52 31 19
51 20 393 20 342 0 362 -1 393 -20z m1959 -1 c68 -42 68 -41 68 -464 0 -423 0
-424 -68 -465 -30 -19 -53 -20 -398 -20 -513 0 -471 -45 -467 495 3 358 4 382
23 409 10 16 33 37 50 47 28 18 57 19 394 19 352 0 365 -1 398 -21z m1951 1
c18 -11 41 -34 52 -52 19 -31 20 -51 20 -411 0 -414 1 -409 -60 -461 l-32 -26
-371 0 c-348 0 -374 1 -401 19 -15 10 -37 32 -47 47 -18 27 -19 54 -19 421 l0
392 32 37 c18 20 41 40 52 44 12 5 183 9 381 9 342 1 362 0 393 -19z"></path>
      <path d="M4042 6053 l3 -2418 215 -3 c118 -2 324 0 458 3 l242 7 0 1054 0
1054 548 0 547 -1 665 -1036 c366 -570 673 -1047 683 -1060 l18 -23 357 1
c196 1 434 4 528 8 l171 6 -793 1214 c-437 667 -794 1217 -794 1221 0 5 344
543 765 1195 421 653 765 1189 765 1191 0 2 -237 4 -527 4 l-528 0 -72 -113
c-447 -703 -833 -1306 -1055 -1649 l-270 -418 -504 0 -504 0 0 1090 0 1090
-460 0 -460 0 2 -2417z"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'KnkLogo',
  props: {
    color: {
      type: String,
      required: false,
      default: '#fff',
    },
    size: {
      type: String,
      required: false,
      default: '32',
    },
  }
}
</script>
