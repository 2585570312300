<template>
  <div class="hero-wr position-relative overflow-hidden">
    <div class="col-md-5 p-4 mx-auto">
      <h1 class="display-4 fw-normal">
        Спіс відэа
      </h1>
      <p class="lead fw-normal">
        У гэтым раздзеле вы можаце глядзець некаторыя відэа з нашым агучваннем на беларускую мову!
      </p>
    </div>
  </div>
</template>

<style scoped>
.hero-wr {
  background: #000;
  background: url('../../assets/img/bg/bg2.jpg');
  background-position: 50% 50%;
}
h1,
.lead {
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .7);
}
</style>

<script>
export default {
  name: 'BlockVideoList',
  props: {
    msg: String
  }
}
</script>
