<template>
  <div class="pg-wrap">
    <PageHeader />
    <main>
      <component :is="currentView" />
    </main>
    <PageFooter />
  </div>
</template>

<script setup>
import {computed} from 'vue'
import 'bootstrap/dist/css/bootstrap.css';
import PageHeader from "./components/PageHeader.vue";
import PageAbout from './components/Pages/PageAbout.vue';
import PageFooter from "./components/PageFooter.vue";
import PageVideoList from './components/Pages/Video/PageVideoList.vue';
import PageVideo from './components/Pages/Video/PageVideo.vue';
import PageVideoCourage from './components/Pages/Video/PageVideoCourage.vue';
import PageVideoMMM from './components/Pages/Video/PageVideoMMM.vue';
import PageVideoPPR from './components/Pages/Video/PageVideoPPR.vue';
import PageVideoPRV from './components/Pages/Video/PageVideoPRV.vue';
import PageVideoKPT from './components/Pages/Video/PageVideoKPT.vue';
import PageContact from './components/Pages/PageContact.vue';
import PageNotFound from "./components/Pages/PageNotFound.vue";
import PageHome from "./components/Pages/PageHome.vue";

const routes = {
  '/': PageHome,
  'video': PageVideoList,
  'video/ronja': PageVideo,
  'video/courage': PageVideoCourage,
  'video/mimimishki': PageVideoMMM,
  'video/papierki': PageVideoPPR,
  'video/paravozau': PageVideoPRV,
  'video/kpatrul': PageVideoKPT,
  'about': PageAbout,
  'contact': PageContact,
}

function getRelativeRoute() {
  const currentUrl = window.location.href;
  const baseUrl = window.location.protocol + '//' + window.location.host;
  let relativeRoute = currentUrl.replace(baseUrl, '');
  let pos = relativeRoute.indexOf('?');

  if (pos > 0) {
    relativeRoute = relativeRoute.substring(0, pos);
  }

  if (window.location.hash) {
    relativeRoute = relativeRoute.replace(window.location.hash, '');
  }

  return relativeRoute.replace(/^\/+|\/+$/g, '');
}

const currentPath = getRelativeRoute() || '/';

const currentView = computed(() => {
  return routes[currentPath] || PageNotFound
});

document.addEventListener('contextmenu', event => event.preventDefault());
</script>

<style>
.hidden {
  display: none;
}

.text-right {
  text-align: right;
}

.color-white {
  color: white;
}

.hidden {
  display: none;
}
</style>
