<template>
  <section>
    <div class="container py-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 offset-md-3">
          <h1>Кантакты</h1>
          <p>
            Калі маеце пытанні, прапановы або запыты, калі ласка,
            звяжыцеся з намі праз электронную пошту або сацыяльныя сеткі (спасылкі ўнізе старонкі).
          </p>
          <p>
            Мы заўжды рады бачыць у нашай камандзе новых аўтараў, валанцёраў, перакладчыкаў і рэдактараў.
            Калі вы жадаеце далучыцца да праекта, пішыце на <a href="mailto:kinakong.by@gmail.com">kinakong.by@gmail.com</a>.
          </p>

          <p>
            Аб тэхнічных праблемах, багах паведамляйце распрацоўшчыку на
            <a href="mailto:i@juljan.by">i@juljan.by</a>.
          </p>

          <div hidden="hidden" class="hidden">
            <h2>Форма кантакту</h2>
            <form @submit.prevent="submitForm">
              <div class="mb-3">
                <label class="form-label" for="name">Імя:</label>
                <input class="form-control" type="text" id="name" v-model="form.name" required>
              </div>

              <div class="mb-3">
                <label class="form-label" for="email">Email:</label>
                <input class="form-control" type="email" id="email" v-model="form.email" required>
              </div>

              <div class="mb-3">
                <label class="form-label" for="message">Паведамленне:</label>
                <textarea class="form-control" id="message" v-model="form.message" required></textarea>
              </div>
              <button type="submit" class="btn btn-primary">Адправіць</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageContact',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  mounted() {
    document.title = "Кантакты | KinaKong";
  },
  methods: {
    submitForm() {
      // Logic to handle form submission
      // You can use Axios or any other library to make an API request or process the form data
      // Reset the form fields after submission
      this.form = {
        name: '',
        email: '',
        message: '',
      };
    },
  },
};
</script>
