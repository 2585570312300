<template>
  <section>
    <div class="container">
      <div class="col-6 offset-3 py-5 text-center">
        <article>
          <h1>Старонка не знойдзена</h1>
          <p class="lead">
            Гэтай старонкі на сайце не існуе.
          </p>
          <p class="lead">
            <a href="/" class="btn btn-primary">
              Вярніцеся на галоўную
            </a>
          </p>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
  mounted() {
    document.title = "Старонка 404 | KinaKong";
  },
}
</script>

<style scoped>
article {
  min-height: 42vh;
}
</style>
