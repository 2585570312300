<template>
  <div>
    <BlockVideoList/>
    <BlockPlay/>
  </div>
</template>

<script setup>
import BlockPlay from "@/components/Parts/BlockPlay.vue";
import BlockVideoList from "@/components/Parts/BlockVideoList.vue"

document.title = "Усе відэа | KinaKong";
</script>
